"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoinView = void 0;
const React = require("react");
const JoinLauncherContext_1 = require("../../contexts/join-launcher/JoinLauncherContext");
const CoreContext_1 = require("../../contexts/CoreContext");
const UrlHelper_1 = require("../../helpers/UrlHelper");
const downloadTeamsImg = require("../../images/download_teams_desktop.svg");
const launcherMeetingsImg = require("../../images/launcher_meetings_new2.svg");
const unsupportedBrowserImg = require("../../images/unsupported_browser.svg");
const DownloadState_1 = require("../../models/DownloadState");
const Environment_1 = require("../../models/Environment");
const JoinInfo_1 = require("../../models/JoinInfo");
const TelemetryEvents_1 = require("../../models/telemetry/TelemetryEvents");
const DownloadService_1 = require("../../services/DownloadService");
const Logger_1 = require("../../services/Logger");
const Button_1 = require("../common/Button");
const Footer_1 = require("../common/Footer");
const TeamsLogo_1 = require("../common/TeamsLogo");
const Translate_1 = require("../common/Translate");
const View_1 = require("../common/View");
const DownloadInProgress_1 = require("../common/DownloadInProgress");
const DownloadPotentiallyCompleted_1 = require("../common/DownloadPotentiallyCompleted");
const Ufd_1 = require("../common/Ufd");
require("./joinView.scss");
const HAVING_TROUBLE_TIMEOUT = 2000;
class JoinView extends React.Component {
    constructor(props) {
        super(props);
        this.finalPanelViewReported = false;
        this.getHeaderTitle = (environment, downloadBehavior, joinInfo) => {
            if (this.state.havingTroubleEdge) {
                return "hdr_having_trouble";
            }
            if (joinInfo.type !== JoinInfo_1.JoinType.Meetup) {
                return "hdr_welcome2_header";
            }
            const isBroadcast = this.isBroadcast(joinInfo);
            if (isBroadcast) {
                // For producers/presenters coming from Yammer, the primary scenario is joining the meeting and not watching as attendee
                const isYammerBroadcastDeeplink = this.isYammerDeeplink(joinInfo);
                return isYammerBroadcastDeeplink
                    ? "hdr_broadcast_title" // Join live event
                    : "hdr_broadcast_welcome_header"; // Watch the live event in Microsoft Teams
            }
            if (!downloadBehavior && !environment.isWebClientSupportedBrowser) {
                return "hdr_use_chrome_header";
            }
            return "hdr_welcome_header";
        };
        this.state = {
            havingTroubleEdge: false,
        };
    }
    renderDownloadButton(joinInfo, downloadBehavior) {
        if (!downloadBehavior) {
            return null;
        }
        return (React.createElement(Button_1.Button, { id: "downloadDesktopClient", className: "primary", onClick: () => {
                this.reportPanelAction(TelemetryEvents_1.ModuleNames.Download, joinInfo);
                downloadBehavior.onClick();
            } },
            React.createElement(Translate_1.Translate, { i18n: downloadBehavior.i18n })));
    }
    renderBody(joinInfo, environment, featureFlags, downloadState, launcherService) {
        const downloadBehavior = DownloadService_1.default.getDownloadBehaviour(joinInfo, environment, featureFlags, launcherService, downloadState);
        switch (downloadState.step) {
            case DownloadState_1.DownloadStep.InProgress:
                if (downloadBehavior) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement(DownloadInProgress_1.DownloadInProgress, { onDownloadClick: () => {
                                this.reportPanelAction(TelemetryEvents_1.ModuleNames.Download, joinInfo);
                                downloadBehavior.onClick();
                            } }),
                        React.createElement(Ufd_1.Ufd, { browser: environment.browserName })));
                }
            // break omitted
            case DownloadState_1.DownloadStep.PotentiallyCompleted:
                if (downloadBehavior) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement(DownloadPotentiallyCompleted_1.DownloadPotentiallyCompleted, { onDownloadClick: () => {
                                this.reportPanelAction(TelemetryEvents_1.ModuleNames.Download, joinInfo);
                                downloadBehavior.onClick();
                            }, onLaunchClick: () => {
                                this.reportPanelAction(TelemetryEvents_1.ModuleNames.JoinInApp, joinInfo);
                                this.props.onLaunchClick();
                            }, onWebLaunchClick: this.onOpenTeamsInBrowserClick(launcherService, joinInfo) }),
                        React.createElement(Ufd_1.Ufd, { browser: environment.browserName })));
                }
            // break omitted
            default:
                return (React.createElement(React.Fragment, null,
                    this.joinView(joinInfo, downloadBehavior, environment, featureFlags, launcherService),
                    environment.isNativeClientSupportedOS && (React.createElement("p", { className: "desktopMessage" },
                        React.createElement("span", null,
                            React.createElement(Translate_1.Translate, { i18n: "hdr_try_again_text" })),
                        "\u00A0",
                        React.createElement("a", { className: "launchLink", id: "tryProtocolLaunchTeamsClient", href: "#", onClick: () => {
                                this.reportPanelAction(TelemetryEvents_1.ModuleNames.JoinInApp, joinInfo);
                                this.props.onLaunchClick();
                            } },
                            React.createElement(Translate_1.Translate, { i18n: "hdr_lnk_launch_skype" })))),
                    this.state.havingTroubleEdge && (React.createElement("p", { className: "desktopMessage havingProblems" },
                        React.createElement("span", null,
                            React.createElement(Translate_1.Translate, { i18n: "txt_having_other_problems" })),
                        "\u00A0",
                        React.createElement("a", { className: "launchLink", id: "tryProtocolLaunchTeamsClient", href: "#", onClick: this.onLearnMoreClick(launcherService, joinInfo) },
                            React.createElement(Translate_1.Translate, { i18n: "txt_get_more_help" }))))));
        }
    }
    render() {
        return (React.createElement(JoinLauncherContext_1.JoinLauncherContextConsumer, null, ({ joinInfo, downloadState }) => {
            this.reportSuccessPanelView("join_launcher_loaded", joinInfo);
            return (React.createElement(View_1.View, { className: "joinView" },
                React.createElement("div", { className: "header", role: "banner" },
                    React.createElement(TeamsLogo_1.TeamsLogo, null)),
                React.createElement("div", { className: "body column centered", role: "main" },
                    React.createElement(CoreContext_1.CoreContextConsumer, null, ({ environment, featureFlags, launcherService }) => (React.createElement(React.Fragment, null,
                        React.createElement("img", { className: "partyImage", id: "party-image", src: this.getIllustrationSrc(joinInfo, environment.isWebClientSupportedBrowser), "aria-hidden": "true" }),
                        this.renderBody(joinInfo, environment, featureFlags, downloadState, launcherService))))),
                React.createElement(Footer_1.Footer, { onLinkClick: () => this.reportPanelAction(TelemetryEvents_1.ModuleNames.ShowThirdPartyNotice, joinInfo) })));
        }));
    }
    joinView(joinInfo, downloadBehavior, environment, featureFlags, launcherService) {
        const isBroadcast = this.isBroadcast(joinInfo);
        const broadcastYammerAttendee = this.isYammerDeeplink(joinInfo);
        const joinInEdge = joinInfo.type === JoinInfo_1.JoinType.Meetup &&
            !environment.isWebClientSupportedBrowser &&
            environment.isWindows10 &&
            environment.browserName !== Environment_1.Browsers.Edge &&
            !isBroadcast &&
            !this.state.havingTroubleEdge;
        // When is not a meetup type we always want to show join on web btn because it doesn't depend on browser support
        // When is meetup and not yammer broadcast attendee we check if the browser is supported or not supported and is a broadcast link type
        const joinOnWeb = (!joinInEdge && joinInfo.type !== JoinInfo_1.JoinType.Meetup) ||
            (joinInfo.type === JoinInfo_1.JoinType.Meetup &&
                !broadcastYammerAttendee &&
                (environment.isWebClientSupportedBrowser ||
                    (!environment.isWebClientSupportedBrowser && isBroadcast)));
        return (React.createElement(React.Fragment, null,
            joinInfo.type === JoinInfo_1.JoinType.Meetup &&
                !isBroadcast &&
                !environment.isWebClientSupportedBrowser &&
                !this.state.havingTroubleEdge && (React.createElement("p", { className: "noSupport" },
                React.createElement(Translate_1.Translate, { i18n: "hdr_no_browser_support_header" }))),
            React.createElement("h1", { id: "join-prompt-header", className: "title" },
                React.createElement(Translate_1.Translate, { i18n: this.getHeaderTitle(environment, downloadBehavior, joinInfo) })),
            this.state.havingTroubleEdge && (React.createElement("p", { className: "tryEdgeAgain" },
                React.createElement("a", { className: "launchLink", href: "#", onClick: this.onOpenTeamsInEdgeClick(launcherService, joinInfo, TelemetryEvents_1.ModuleNames.TryEdgeAgain) },
                    React.createElement(Translate_1.Translate, { i18n: "hdr_lnk_try_joining_edge_again" })))),
            React.createElement("div", { className: "buttonsContainer" },
                !featureFlags.hideDownloadButton &&
                    this.renderDownloadButton(joinInfo, downloadBehavior),
                joinOnWeb && (React.createElement(Button_1.Button, { "data-tid": "joinOnWeb", id: "openTeamsClientInBrowser", onClick: this.onOpenTeamsInBrowserClick(launcherService, joinInfo) },
                    React.createElement(Translate_1.Translate, { i18n: isBroadcast
                            ? "hdr_watch_broadcast_btn"
                            : joinInfo.type === JoinInfo_1.JoinType.Meetup
                                ? "hdr_btn_join_on_web"
                                : "hdr_btn_launch_open_teams" }))),
                joinInEdge && !environment.isWebClientSupportedBrowser && (React.createElement(Button_1.Button, { "data-tid": "joinInEdge", id: "openTeamsClientInEdge", onClick: this.onOpenTeamsInEdgeClick(launcherService, joinInfo, TelemetryEvents_1.ModuleNames.JoinInEdge) },
                    React.createElement(Translate_1.Translate, { i18n: "hdr_btn_join_in_edge" }))),
                !environment.isWebClientSupportedBrowser && !environment.isNativeClientSupportedOS && (React.createElement(Button_1.Button, { id: "learnMore", onClick: this.onLearnMoreClick(launcherService, joinInfo) },
                    React.createElement(Translate_1.Translate, { i18n: "hdr_btn_learn_more" }))))));
    }
    onOpenTeamsInEdgeClick(launcherService, joinInfo, moduleName) {
        return () => {
            this.reportPanelAction(moduleName, joinInfo);
            launcherService.redirectToWebJoin(undefined, true);
            window.setTimeout(() => {
                this.setState({ havingTroubleEdge: true });
            }, HAVING_TROUBLE_TIMEOUT);
        };
    }
    onOpenTeamsInBrowserClick(launcherService, joinInfo) {
        return () => {
            this.reportPanelAction(TelemetryEvents_1.ModuleNames.JoinOnWeb, joinInfo);
            launcherService.redirectToWebJoin();
        };
    }
    onLearnMoreClick(launcherService, joinInfo) {
        return () => {
            this.reportPanelAction(TelemetryEvents_1.ModuleNames.LearnMore, joinInfo);
            launcherService.redirectToLearnMore();
        };
    }
    getIllustrationSrc(joinInfo, isSupportedBrowser) {
        if (joinInfo.type === JoinInfo_1.JoinType.Meetup) {
            return isSupportedBrowser || this.isBroadcast(joinInfo)
                ? launcherMeetingsImg
                : unsupportedBrowserImg;
        }
        else {
            return downloadTeamsImg;
        }
    }
    isYammerDeeplink(joinInfo) {
        const sourceParam = UrlHelper_1.UrlHelper.getQueryParameter("lmsrc", joinInfo.conversationUrl);
        return !!sourceParam && sourceParam.toLowerCase() === "yammer";
    }
    isBroadcast(joinInfo) {
        return (joinInfo.meetingInfo && joinInfo.meetingInfo.isBroadcast) || false;
    }
    reportSuccessPanelView(name, joinInfo, props) {
        if (!this.finalPanelViewReported) {
            const panelViewEvent = new TelemetryEvents_1.PanelViewEvent(name, joinInfo, Object.assign(Object.assign({}, props), { launchResult: this.props.launchResult }));
            Logger_1.default.logUserBiPanelView(panelViewEvent);
            this.finalPanelViewReported = true;
        }
    }
    reportPanelAction(moduleName, joinInfo) {
        const panelActionEvent = new TelemetryEvents_1.PanelActionEvent(TelemetryEvents_1.PanelActionScenarioNames.JoinView, moduleName, joinInfo);
        Logger_1.default.logUserBiPanelAction(panelActionEvent);
    }
}
exports.JoinView = JoinView;
