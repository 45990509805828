"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModernLifeSimplifiedView = void 0;
const TeamsLogo_1 = require("../common/TeamsLogo");
const teamsLogoImg = require("../../images/v2/logo_teams.svg");
const tflLogoWin11Img = require("../../images/v3/tfl_win11_logo.svg");
const tflCommunityBannerImg = require("../../images/v3/tfl_community_banner.png");
const tflCommunityEventBannerImg = require("../../images/v3/tfl_community_event_banner.png");
const teamsInsiderBannerImg = require("../../images/v3/tfl_insiders_banner.png");
const schoolConnectionBannerImg = require("../../images/v3/school_connection_desktop_banner.png");
require("./ModernLifeSimplifiedView.scss");
const React = require("react");
const CoreContext_1 = require("../../contexts/CoreContext");
const JoinLauncherContext_1 = require("../../contexts/join-launcher/JoinLauncherContext");
const JoinInfo_1 = require("../../models/JoinInfo");
const ServerParamsService_1 = require("../../services/ServerParamsService");
const Translate_1 = require("../common/Translate");
const TelemetryEvents_1 = require("../../models/telemetry/TelemetryEvents");
const Logger_1 = require("../../services/Logger");
const ConfigurationService_1 = require("../../services/ConfigurationService");
const CommunityEventsHelper_1 = require("../../helpers/CommunityEventsHelper");
class ModernLifeSimplifiedView extends React.PureComponent {
    constructor(props) {
        var _a;
        super(props);
        this.buttons = (_a = this.props.buttons) === null || _a === void 0 ? void 0 : _a.reduce((r, key) => (Object.assign(Object.assign({}, r), { [key.props["data-tid"]]: key })), {});
    }
    render() {
        return (React.createElement(CoreContext_1.CoreContextConsumer, null, ({ environment, featureFlags }) => (React.createElement(JoinLauncherContext_1.JoinLauncherContextConsumer, null, ({ joinInfo }) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            const isCommunityEventAndEnabled = featureFlags.enableTflCommunityEvents &&
                CommunityEventsHelper_1.CommunityEventsHelper.isCommunityEvent(joinInfo);
            const bannerSrc = this.getBanner(joinInfo);
            let title;
            let members;
            let description;
            let addToCalendar;
            let instructionsLearnMore;
            let logo = environment.isWindows11 && environment.isTflEnvironment
                ? tflLogoWin11Img
                : teamsLogoImg;
            let logoClass = "";
            if (isCommunityEventAndEnabled) {
                logo = undefined;
            }
            title = this.props.instructionsPanelTitle ? (React.createElement(Translate_1.Translate, { i18n: this.props.instructionsPanelTitle })) : undefined;
            const hasActions = ((_a = this.buttons) === null || _a === void 0 ? void 0 : _a.joinOnWeb) || ((_b = this.buttons) === null || _b === void 0 ? void 0 : _b.joinInApp);
            switch (joinInfo.type) {
                case JoinInfo_1.JoinType.TeamsInsider:
                    hasActions
                        ? (description = React.createElement(Translate_1.Translate, { i18n: "hdr_teams_insider_description" }))
                        : (instructionsLearnMore = (React.createElement("a", { href: ConfigurationService_1.configurationService.getConfig().urls.absoluteUrls
                                .tflInsidersLearnMoreLink, target: "_blank", rel: "noopener noreferrer", onClick: () => this.reportPanelAction(TelemetryEvents_1.ModuleNames.LearnMore, joinInfo), id: "instructionsLearnMore" },
                            React.createElement(Translate_1.Translate, { i18n: "hdr_btn_learn_more" }))));
                    break;
                case JoinInfo_1.JoinType.SchoolConnection:
                    logo = undefined;
                    instructionsLearnMore = (React.createElement("a", { href: ConfigurationService_1.configurationService.getConfig().urls.absoluteUrls
                            .schoolConnectionLearnMoreLink, target: "_blank", rel: "noopener noreferrer", onClick: () => this.reportPanelAction(TelemetryEvents_1.ModuleNames.LearnMore, joinInfo), id: "instructionsLearnMore" },
                        React.createElement(Translate_1.Translate, { i18n: "hdr_btn_visit_school_connection_website" })));
                    break;
            }
            // Update experience details from request context
            if (featureFlags.enableTflRequestContextForDesktop) {
                const context = ServerParamsService_1.default.getServerParams().requestContext;
                if (context.avatarImage) {
                    logo = context.avatarImage;
                    logoClass = "logoCustom";
                }
                title = (_c = context.title) !== null && _c !== void 0 ? _c : title;
                description = (_d = context.description) !== null && _d !== void 0 ? _d : description;
                if (isCommunityEventAndEnabled) {
                    const eventDescription = this.getCommunityEventDescription(context);
                    if (eventDescription) {
                        description = eventDescription;
                    }
                    if (featureFlags.enableTflCommunityEventsAddToCalendar) {
                        addToCalendar = this.getAddToCalendarOption(joinInfo, context);
                    }
                }
                members = context.membersCount ? (React.createElement("p", null,
                    context.membersCount,
                    "\u00A0",
                    React.createElement(Translate_1.Translate, { i18n: "text_members" }))) : undefined;
            }
            const buttonsContainer = featureFlags.enableTflWin11JoinInAppPrimaryAction && environment.isWindows11 ? (React.createElement("div", { className: "buttonsContainer" }, (_e = this.buttons) === null || _e === void 0 ? void 0 :
                _e.joinInApp,
                " ", (_f = this.buttons) === null || _f === void 0 ? void 0 :
                _f.joinOnWeb)) : (React.createElement("div", { className: "buttonsContainer" }, (_g = this.buttons) === null || _g === void 0 ? void 0 :
                _g.joinOnWeb,
                " ", (_h = this.buttons) === null || _h === void 0 ? void 0 :
                _h.joinInApp));
            return (React.createElement("div", { className: "modernLifeSimplifiedView" },
                React.createElement("div", { className: "mainActionsContent" },
                    React.createElement("div", { className: "banner" }, bannerSrc ? React.createElement("img", { className: "illustration", src: bannerSrc }) : null),
                    logo ? (React.createElement("div", { className: "headerContainer" },
                        React.createElement("div", { className: "logoContainer" },
                            React.createElement(TeamsLogo_1.TeamsLogo, { src: logo, className: logoClass })))) : null,
                    React.createElement("div", { className: "contentContainer" },
                        React.createElement("div", { className: "title" }, title),
                        members,
                        React.createElement("div", { className: "description" }, description),
                        addToCalendar ? addToCalendar : null,
                        this.props.instructionsPanelText ? (React.createElement("p", { id: "instructions" },
                            React.createElement(Translate_1.Translate, { i18n: this.props.instructionsPanelText }))) : null,
                        instructionsLearnMore ? instructionsLearnMore : null),
                    hasActions ? React.createElement("div", { className: "actionsContainer" }, buttonsContainer) : null),
                ((_j = this.buttons) === null || _j === void 0 ? void 0 : _j.download) ? (React.createElement("div", { className: "secondaryActionsContent" }, (_k = this.buttons) === null || _k === void 0 ? void 0 : _k.download)) : (React.createElement("img", { src: teamsLogoImg, className: "teamsImg", "aria-hidden": true }))));
        }))));
    }
    getBanner(joinInfo) {
        if (CommunityEventsHelper_1.CommunityEventsHelper.isCommunityEvent(joinInfo)) {
            return tflCommunityEventBannerImg;
        }
        if (joinInfo.type === JoinInfo_1.JoinType.TeamsInsider) {
            return teamsInsiderBannerImg;
        }
        if (joinInfo.type === JoinInfo_1.JoinType.SchoolConnection) {
            return schoolConnectionBannerImg;
        }
        return joinInfo.type === JoinInfo_1.JoinType.TflCommunity || joinInfo.type === JoinInfo_1.JoinType.Message
            ? tflCommunityBannerImg
            : undefined;
    }
    getAddToCalendarOption(joinInfo, context) {
        const blobUrl = CommunityEventsHelper_1.CommunityEventsHelper.createEventICalBlob(context, joinInfo);
        return blobUrl ? (React.createElement("a", { id: "addToCalendar", href: blobUrl, download: `${context.iCalUid}.ics`, target: "_blank", rel: "noreferrer", onClick: () => {
                this.reportPanelAction(TelemetryEvents_1.ModuleNames.AddToCalendar, joinInfo);
            } },
            React.createElement(Translate_1.Translate, { i18n: "btn_add_to_calendar" }))) : undefined;
    }
    getCommunityEventDescription(context) {
        if (context.startTime && context.endTime) {
            return (React.createElement("div", null,
                CommunityEventsHelper_1.CommunityEventsHelper.getEventDescriptionTimeLine(context),
                React.createElement("br", null),
                CommunityEventsHelper_1.CommunityEventsHelper.getEventDescriptionDateLine(context)));
        }
        return undefined;
    }
    reportPanelAction(moduleName, joinInfo) {
        const panelActionEvent = new TelemetryEvents_1.PanelActionEvent(TelemetryEvents_1.PanelActionScenarioNames.ModernView, moduleName, joinInfo);
        Logger_1.default.logUserBiPanelAction(panelActionEvent);
    }
}
exports.ModernLifeSimplifiedView = ModernLifeSimplifiedView;
