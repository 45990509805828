"use strict";
/* -force_new_hash- */
Object.defineProperty(exports, "__esModule", { value: true });
const webjs_sdk_1 = require("@aria/webjs-sdk");
const react_dom_1 = require("react-dom");
require("./app.scss");
const AppBootstrapper_1 = require("./AppBootstrapper");
const Utils_1 = require("./helpers/Utils");
const TelemetryEvents_1 = require("./models/telemetry/TelemetryEvents");
const FeaturesService_1 = require("./services/FeaturesService");
const Logger_1 = require("./services/Logger");
const TelemetryScenarioTracker_1 = require("./services/TelemetryScenarioTracker");
const OneDsSerivce_1 = require("./services/OneDsSerivce");
const ServerParamsService_1 = require("./services/ServerParamsService");
const ConfigurationService_1 = require("./services/ConfigurationService");
const LogsCollectorService_1 = require("./services/LogsCollectorService");
// ServerParamsService must always initialize before ConfigurationService to pick the environment from PDS
ServerParamsService_1.default.init();
ConfigurationService_1.configurationService.init(ServerParamsService_1.default.getServerParams().environment);
if (FeaturesService_1.default.getFeatureFlags().enableAria) {
    Logger_1.default.init();
}
OneDsSerivce_1.OneDS.init();
TelemetryScenarioTracker_1.TelemetryScenarioTracker.init();
const startScenario = TelemetryScenarioTracker_1.TelemetryScenarioTracker.getOrCreateScenario(TelemetryScenarioTracker_1.ScenarioNames.JoinLauncherStart);
startScenario.start();
window.onerror = (message, source, line, col, error) => {
    const errorEvent = new TelemetryEvents_1.GenericErrorEvent({
        message: Utils_1.Utils.scrubEuii(message.toString()),
        error: JSON.stringify({
            source: Utils_1.Utils.stripQSPFromUrl(source),
            line,
            col,
            error: Utils_1.Utils.scrubEuii((error === null || error === void 0 ? void 0 : error.stack) || ""),
        }),
    });
    Logger_1.default.log(errorEvent, webjs_sdk_1.AWTEventPriority.Immediate_sync);
};
window.addEventListener("beforeunload", () => {
    // in case the start scenario did not complete before app is unloaded, cancel it
    startScenario.cancel("Unloaded");
    Logger_1.default.log(new TelemetryEvents_1.UnloadLauncherEvent());
    Logger_1.default.flushAndTeardown();
});
document.addEventListener("keydown", event => {
    if (Utils_1.Utils.logsCollectorKeyCombination(event)) {
        LogsCollectorService_1.default.download();
    }
});
const container = document.getElementById("launcherApp");
if (container) {
    new AppBootstrapper_1.default().bootstrap().then(element => {
        (0, react_dom_1.render)(element, container);
        startScenario.stop();
    }, error => startScenario.fail(JSON.stringify(error)));
}
