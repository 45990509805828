"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandingService = void 0;
const TelemetryEvents_1 = require("../models/telemetry/TelemetryEvents");
const Logger_1 = require("./Logger");
const Utils_1 = require("../helpers/Utils");
const ConfigurationService_1 = require("./ConfigurationService");
const PromisesHelper_1 = require("../helpers/PromisesHelper");
class BrandingService {
    constructor(document, requestTimeout, brandingWithImages) {
        this.document = document;
        this.requestTimeout = requestTimeout;
        this.brandingWithImages = brandingWithImages;
        this.brandingStarted = false;
        this.brandingAppliedDeferred = PromisesHelper_1.default.createDefered();
        this.isBrandingApplied = () => this.brandingAppliedDeferred.promise;
        this.fastPlaceholder = document.createElement("div");
        document.body.appendChild(this.fastPlaceholder);
        this.brandingServiceUrl = ConfigurationService_1.configurationService.getConfig().urls.absoluteUrls.brandingService;
    }
    startBranding(tenantId, organizerId) {
        this.brandingEventProps = { tenantId, organizerId };
        let isTimeoutExpired = false;
        if (this.brandingStarted) {
            this.applyUnbrandedTheme();
            this.brandingAppliedDeferred.resolve(false);
            return;
        }
        const timeoutPromise = new Promise((resolve, _reject) => {
            this.timeoutId = window.setTimeout(() => {
                this.brandingEventProps.status = "incomplete";
                isTimeoutExpired = true;
                resolve();
            }, this.requestTimeout);
        });
        this.importFastElementLibrary()
            .then(fastElementLib => {
            this.brandingDelta = Date.now();
            this.updateBrandingSteps("BrandingLibImported");
            this.brandingStarted = true;
            this.getBrandedColors = fastElementLib.default;
            return this.fetchBrandingThemes(tenantId, organizerId).then(brandingTheme => {
                this.brandingContent = this.getBrandingThemeContent(brandingTheme);
                this.updateBrandingSteps("BrandingThemeContent");
                return Promise.race([timeoutPromise, this.fetchImages(brandingTheme)]).then(() => {
                    if (isTimeoutExpired) {
                        this.applyUnbrandedTheme();
                    }
                    else {
                        clearTimeout(this.timeoutId);
                        this.updateBrandingSteps("BrandingCompleted");
                        this.brandingEventProps.status = "success";
                        this.applyBrandingTheme(brandingTheme);
                        this.brandingAppliedDeferred.resolve(true);
                    }
                });
            });
        })
            .catch((error) => {
            this.brandingEventProps.error = Utils_1.Utils.scrubEuii(error);
            // Show unbranded theme if branding fails at any step
            this.applyUnbrandedTheme();
            this.brandingAppliedDeferred.resolve(false);
        })
            .finally(() => {
            this.brandingEventProps.brandingSteps = JSON.stringify(this.brandingSteps);
            this.brandingEventProps.brandingTheme = JSON.stringify(this.brandingContent);
            const brandingEvent = new TelemetryEvents_1.BrandingEvent(this.brandingEventProps);
            Logger_1.default.log(brandingEvent);
        });
    }
    fetchImages(theme) {
        if ((this.brandingContent &&
            !this.brandingContent.hasBackground &&
            !this.brandingContent.hasLogo) ||
            !this.brandingWithImages) {
            return Promise.resolve();
        }
        const brandingJoinLauncherImages = [
            { id: "logoImagePreAuthUri", url: theme.logoImagePreAuthUri },
            {
                id: "backgroundImagePreAuthUri",
                url: theme.backgroundImagePreAuthUri,
            },
        ];
        return Utils_1.Utils.fetchImages(brandingJoinLauncherImages)
            .then(() => {
            this.updateBrandingSteps("BrandingImagesFetched");
            return Promise.resolve();
        })
            .catch(errorImageId => {
            this.brandingEventProps.message = "brandingImageFetchError";
            this.brandingEventProps.status = "failure";
            this.brandingEventProps.error = errorImageId;
            return Promise.reject(errorImageId);
        });
    }
    // replaces the imgpsh_fullsize? with imgo? to get the image in the correct size based on
    // https://eng.ms/docs/experiences-devices/m365-core/ic3/messaging/async-media-distribution/views
    changeImageSizePropToIMGO(fullsizeImage) {
        return fullsizeImage.replace("imgpsh_fullsize?", "imgo?");
    }
    importFastElementLibrary() {
        return (
        // eslint-disable-next-line import/dynamic-import-chunkname
        Promise.resolve().then(() => require("../branding")).catch(e => {
            this.brandingEventProps.status = "failure";
            this.brandingEventProps.message = "brandingLibImportError";
            return Promise.reject(e);
        }));
    }
    fetchBrandingThemes(tenantId, organizerId) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                var _a, _b;
                this.updateBrandingSteps("BrandingFetchStarted");
                let fetchResponse;
                let fetchResponseJSON;
                try {
                    fetchResponse = yield window.fetch(`${this.brandingServiceUrl}?tenantId=${tenantId}&userId=${organizerId}`, {
                        mode: "cors",
                        credentials: "omit",
                    });
                    fetchResponseJSON = yield fetchResponse.json();
                    this.brandingEventProps.xServerRequestId =
                        (_a = fetchResponse.headers.get("x-serverrequestid")) !== null && _a !== void 0 ? _a : undefined;
                    this.brandingEventProps.xMsEdgeRef = (_b = fetchResponse.headers.get("X-MSEdge-Ref")) !== null && _b !== void 0 ? _b : undefined;
                    if (!fetchResponse.ok) {
                        clearTimeout(this.timeoutId);
                        this.brandingEventProps.status = "failure";
                        this.brandingEventProps.message = "brandingServerError";
                        reject(fetchResponse.status.toString());
                    }
                    const brandingTheme = this.getBrandingTheme(fetchResponseJSON);
                    if (!brandingTheme) {
                        clearTimeout(this.timeoutId);
                        this.brandingEventProps.message = "brandingThemeMissingError";
                        this.brandingEventProps.status = "success";
                        reject();
                    }
                    else {
                        resolve(brandingTheme);
                    }
                }
                catch (error) {
                    clearTimeout(this.timeoutId);
                    this.brandingEventProps.status = "failure";
                    this.brandingEventProps.message = "failedToFetchBranding";
                    reject(error.message);
                }
                finally {
                    this.updateBrandingSteps("BrandingFetchFinished");
                    this.brandingEventProps.time = this.brandingSteps.BrandingFetchFinished;
                }
            }));
        });
    }
    getBrandingTheme(jsonResponse) {
        if (jsonResponse &&
            jsonResponse.policy &&
            jsonResponse.policy.defaultTheme &&
            jsonResponse.policy.meetingBrandingThemes) {
            const theme = jsonResponse.policy.meetingBrandingThemes.find(({ identity }) => identity === jsonResponse.policy.defaultTheme);
            if (theme && theme.enabled && theme.brandAccentColor) {
                return {
                    backgroundImagePreAuthUri: this.brandingWithImages
                        ? theme.backgroundImageLightPreAuthUri
                            ? this.changeImageSizePropToIMGO(theme.backgroundImageLightPreAuthUri)
                            : theme.backgroundImageDarkPreAuthUri
                                ? this.changeImageSizePropToIMGO(theme.backgroundImageDarkPreAuthUri)
                                : ""
                        : "",
                    logoImagePreAuthUri: this.brandingWithImages
                        ? theme.logoImageLightPreAuthUri
                            ? this.changeImageSizePropToIMGO(theme.logoImageLightPreAuthUri)
                            : theme.logoImageDarkPreAuthUri
                                ? this.changeImageSizePropToIMGO(theme.logoImageDarkPreAuthUri)
                                : ""
                        : "",
                    brandAccentColor: theme.brandAccentColor,
                    displayName: theme.displayName,
                    enabled: theme.enabled,
                    identity: theme.identity,
                };
            }
        }
        return undefined;
    }
    getBrandingThemeContent(brandingTheme) {
        const brandingThemeContent = {};
        if (brandingTheme.brandAccentColor) {
            brandingThemeContent.hasColor = true;
        }
        if (brandingTheme.backgroundImagePreAuthUri) {
            brandingThemeContent.hasBackground = true;
        }
        if (brandingTheme.logoImagePreAuthUri) {
            brandingThemeContent.hasLogo = true;
        }
        return brandingThemeContent;
    }
    updateBrandingSteps(step) {
        this.brandingSteps = Object.assign(Object.assign({}, this.brandingSteps), { [step]: Date.now() - this.brandingDelta });
        this.brandingDelta = Date.now();
    }
    applyBrandingTheme(brandingTheme) {
        let brandingClass = "brandingNoImages";
        // Logo and background both available
        if (brandingTheme.logoImagePreAuthUri && brandingTheme.backgroundImagePreAuthUri) {
            brandingClass = "brandingAllImages";
            Utils_1.Utils.applyImageToContainer(brandingTheme.logoImagePreAuthUri, "logoContainer");
            Utils_1.Utils.applyImageToContainer(brandingTheme.backgroundImagePreAuthUri, "headerContainer");
        }
        // Only background, no logo
        else if (!brandingTheme.logoImagePreAuthUri && brandingTheme.backgroundImagePreAuthUri) {
            brandingClass = "brandingBackgroundOnly";
            Utils_1.Utils.applyImageToContainer(brandingTheme.backgroundImagePreAuthUri, "headerContainer");
        }
        // Only logo, no branding
        else if (!brandingTheme.backgroundImagePreAuthUri && brandingTheme.logoImagePreAuthUri) {
            brandingClass = "brandingLogoOnly";
            Utils_1.Utils.applyImageToContainer(brandingTheme.logoImagePreAuthUri, "logoContainer");
        }
        Utils_1.Utils.addClassToContainer(brandingClass, "modernViewContainer");
        // Apply branding colors
        this.setBrandingColors(brandingTheme.brandAccentColor);
        Utils_1.Utils.addClassToContainer("brandingColors", "modernViewContainer");
    }
    setBrandingColors(color) {
        const brandingColors = this.getBrandedColors(this.fastPlaceholder, color);
        // Update branding CSS variables with computed colors
        this.document.documentElement.style.setProperty("--branding-primary-button-color", brandingColors.background);
        this.document.documentElement.style.setProperty("--branding-primary-button-color-hover", brandingColors.backgroundHover);
        this.document.documentElement.style.setProperty("--branding-primary-button-color-active", brandingColors.backgroundPressed);
        this.document.documentElement.style.setProperty("--branding-anchor-color", brandingColors.background);
    }
    applyUnbrandedTheme() {
        this.updateBrandingSteps("ApplyUnbrandedTheme");
        try {
            Utils_1.Utils.addClassToContainer("noBranding", "modernViewContainer");
        }
        catch (e) {
            this.brandingEventProps.error = "applyUnbrandedThemeError";
            this.brandingEventProps.message = Utils_1.Utils.scrubEuii(e);
        }
    }
}
exports.BrandingService = BrandingService;
